import React from "react"
import Image from "../image-component/image-new"
import "./brand-compliant.scss"
import HeroSection from "../hero-section/hero-section"
import Breadcrumbs from "../breadcrumbs/breadcrumbs"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const pageContent = {
    "heroHeading": "Brand Compliant",
    "heroSubheading": "<span>Increase conversions, while staying on-brand and maintaining a cohesive user experience.  Let's accelerate the pace and push experimentation, but in way that excites both the performance marketers and the brand team.</span>",
    "sections": [
        {
            "heading": "On-Brand",
            "text": "Here's the typical tension. Performance marketing got ordered to move fast, break things, and hit their numbers.  Brand marketing got charged with safeguarding the brand and user experience. Nobody got enough resources. <br>The result is turf wars, endless review cycles, and gridlock!",
            "text2": "We value brands and numbers. We bring the resources to get both. <a href='../../full-service-team/' target='_self' rel='noopener noreferrer'><u>Teams</u></a> are staffed to projects with a focus on brand-fit. <a href='../../full-service-team/#design' target='_self' rel='noopener noreferrer'><u>Designers</u></a> have experience with <a href='../../clients/' target='_self' rel='noopener noreferrer'><u>top-tier brands</u></a> and put in the work to internalize your style and make<br> it right the first time.",
            "text3": "On-Brand, elegant, and high-converting.",
            "sectionImage": [
                {
                    "slidePhoto": "crowdstrike_slide_bg.png",
                    "slideCopy": "CrowdStrike has a distinct visual style. Scroll through to see how our designers work with that style to create pages that are both on-brand and high converting.",

                },
                {
                    "slidePhoto": "onbrand-slide2.png",
                    "slideCopy": "Resources page, designed to be both on-brand and high converting.",

                },
                {
                    "slidePhoto": "on-brand-slide1.png",
                    "slideCopy": "The trial page provides a consistent user experience for visitors coming from CrowdStrike’s main site.",

                },
            ]
        },
        {
            "heading": "On-Message",
            "text": "It's got to sound like you and speak your message.  All that internet marketing stuff is fine if you are selling  one wierd trick to lose an inch of belly fat to Buzzfeed subscribers. But, that's might not be quite the right tone for an enterprise security product.",
            "text2": "Our <a href='../../full-service-team/#strategy' target='_self' rel='noopener noreferrer'><u>conversion strategists</u></a> write in your voice. We do the <a href='../../full-service-team/#research' target='_self' rel='noopener noreferrer'><u>research</u></a> to understand your audience and the words they use to describe their problems.  We study your language to understand the way your brand communicates.",
            "text3": "But, it's more than just the words - it's the message. We align around your mission, values, and strategy to focus on communicating the things that are important to your brand.",
            "sectionImage": "on-message-image.png"
        },
        {
            "heading": "Consistent",
            "text": "How do you experiment, while maintaining a consistent customer experiences?",
            "text2": "We use a pilot, validate, and scale methodology.  Start by experimenting on just a single page and isolated experiences like paid landing pages (PLPs).  Sucessful experiences are validated on a wider set, before being scaled across the enterprise.",
            "sectionImage": "brand-compliant-consistent_1.png"
        }



    ],
}

const settings = {
    centerMode: true,
    centerPadding: '0',

    dots: true,
    infinite: true,
    cssEase: 'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 250,
    arrows: true,
    responsive: [
        {
            breakpoint: 768,
            settings: {

                vertical: false,
                dots: true,
                centerMode: true,
                // centerPadding: '0',
                // variableWidth: true,
                slidesToShow: 1,
                // cssEase: 'linear',

            }
        }
    ]

};
export default function BrandCompliant({ vaweColor }) {
    const scrollTo = (selector) => {
        document.querySelector(selector).scrollIntoView()
    }
    return (
        <span className="brand-compliant-wrap">
            <HeroSection heroClassName={'brand-compliant'} vaweColor={vaweColor}>
                <Breadcrumbs currentPage={'Brand Compiant'} parent={'How it Works'} parentPath={'how-it-works'} />
                <h1 className=" hero-heading">{pageContent.heroHeading} </h1>
                <div className="hero-subheading" dangerouslySetInnerHTML={{ __html: pageContent.heroSubheading }} />
                <ul className="brand-navigation">
                    <li onClick={() => scrollTo('.research-brand')}>On-Brand</li>
                    <li className="dots"></li>
                    <li onClick={() => scrollTo('.on-message')}>On-Message</li>
                    <li className="dots"></li>
                    <li onClick={() => scrollTo('.consistent')}>Consistent</li>
                </ul>
            </HeroSection>
            {pageContent.sections.map((item, index) => {
                return (
                    <section
                        key={index}
                        className={
                            index === 0 && 'research-brand' ||
                            index === 1 && 'research-brand on-message blue-bg' ||
                            index === 2 && 'research-brand consistent'
                        }
                    >
                        <div className="container">
                            <div className={index % 2 ? "section-wrapper " : "section-wrapper flipped"}>
                                <div className="brand-info">
                                    <div className="features-brand">
                                        <div className="features-brand-detail">
                                            <h6 className="features-brand-title">{item.heading}</h6>
                                            <p className="features-brand-description" dangerouslySetInnerHTML={{ __html: item.text }} />
                                            <p className="features-brand-description" dangerouslySetInnerHTML={{ __html: item.text2 }} />
                                            <p className="features-brand-description" dangerouslySetInnerHTML={{ __html: item.text3 }} />
                                        </div>
                                    </div>

                                </div>
                                <div className="section-image-slider">
                                    {typeof item.sectionImage === 'object' ? <Slider className="client-brand-slider d-flex" {...settings}>
                                        {item.sectionImage.map((item, index) => {
                                            return (
                                                <div className="client-brand-card-wrapper d-flex" key={index}>
                                                    <div className="clients-brand-image">
                                                        <Image sourceFolder={'brand-compliant'} imgName={item.slidePhoto} className={'company-person'} />
                                                    </div>
                                                    <div className="clients-brand-title">
                                                        <p className="clients-brand-title-description">{item.slideCopy}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </Slider> : <Image sourceFolder={'brand-compliant'} imgName={item.sectionImage} />
                                    }
                                </div>
                            </div>
                        </div>

                    </section>
                )
            })}
        </span >

    )
}