import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BrandCompliantComponent from "../components/brand-compliant/brand-compliant"

const BrandCompliant = () => (
  <Layout  nav={'hidden'}>
    <Seo title="Brand Compliant" />
    <BrandCompliantComponent vaweColor={['#DAF0FF', '#F6FBFD', '#FFF']}/>
  </Layout>
)

export default BrandCompliant
