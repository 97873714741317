import React from "react"
import Waves from "../../components/waves/waves"
import SiteNavigation from "../../components/nav/nav"
import './hero-section.scss'

export default function HeroSection({ children, heroClassName, vaweColor }) {

    return (
        <div className={`hero-page-section hero-section-${heroClassName}`}>
            <SiteNavigation />
            <div className="container">
                <div className={`${heroClassName}-wrapper hero-content-wrapper`}>
                    {children}
                </div>
            </div>
            <Waves vaweColor={vaweColor}/>
        </div>
    )

}