import React from "react"
import "./waves.scss"

export default function Waves({ vaweColor }) {
    return (
        <div className="wave">
            <svg className="separator-waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 80 28" preserveAspectRatio="none" shapeRendering="auto">
                <defs>
                    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                </defs>
                <g className="separator-waves-parallax">
                    {vaweColor !== undefined ? vaweColor.map((item, index) => {
                        return <use key={index} xlinkHref="#gentle-wave" x="48" y="0" fill={item}></use>
                    }) : <use xlinkHref="#gentle-wave" x="48" y="0" fill="#FFF"></use>}
                </g>
            </svg>
        </div>
    )
}