import React from "react"
import "./breadcrumbs.scss"
import { Link } from 'gatsby'

export default function Breadcrumbs({currentPage, parent, parentPath}) {
    return (
        <div className="breadcrumb">
            <div className="container">
                <ul className="breadcrumb-item">
                    <li><Link to="/">Home</Link></li>
                    {parent && parentPath && <li><Link to={`/${parentPath}`}>{parent}</Link></li>}
                    <li><span>{currentPage}</span></li>
                </ul>
            </div>
        </div>
    )
}